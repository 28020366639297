.ContactForm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.ContactForm form {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    z-index: 100;
}

.ContactForm input,
.ContactForm textarea,
.ContactForm button {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: inherit;
}

.ContactForm input[type="text"]:focus,
.ContactForm input[type="email"]:focus,
.ContactForm textarea:focus {
    border-color: #0a7075cc;
    outline: none;
    box-shadow: 0 0 10px rgba(10, 112, 117, 0.289);
}

.ContactForm textarea {
    resize: vertical;
    min-height: 120px;
}

.ContactForm button {
    background-color: #0a7075af;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ContactForm button:hover {
    background-color: #0a6f75;
}

/* Responzivita pro malá zařízení */
@media only screen and (max-width: 600px) {
    .ContactForm form {
        margin: 20px 10px;
    }
}