.About {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-direction: column;


}

@media screen and (max-width: 1100px) {
    .About {
        height: auto;

    }
}

.About_image_container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75vw;
    max-height: 75vh;
    height: fit-content;

    max-width: 2000px;
    margin: 0 auto;

}

@media screen and (max-width: 1100px) {
    .About_image_container {
        flex-direction: column;
        width: 100vw;
        max-height: none;
        height: fit-content;
    }
}

.About_image_container_left,
.About_image_container_right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    /* Rozdělí šířku kontejneru rovnoměrně */
    height: 100%;
    flex: 1;
    /* Zajistí, že každý kontejner bude mít stejný prostor */
}

@media screen and (max-width: 1100px) {

    .About_image_container_left,
    .About_image_container_right {
        width: 100%;
        height: 100%;
    }
}

.About_image_container_left img,
.About_image_container_right img {
    width: 100%;

    height: 100%;

    object-fit: cover;
    max-width: 100%;

    max-height: 70vh;

}

@media screen and (max-width: 1100px) {

    .About_image_container_left img,
    .About_image_container_right img {
        height: auto;
        max-height: 100%;

    }
}


.About_image_container_left_text {
    position: absolute;
    top: -15%;
    display: inline-block;

}

@media screen and (max-width: 1100px) {

    .About_image_container_left_text {
        top: auto;
        bottom: 0%;


    }
}

.About_image_container_left_text h2 {
    color: #1F273B;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(1vw, 120px, 5.5vw);
    font-style: normal;
    font-weight: 400;
    line-height: 85%;

    text-transform: uppercase;

}

@media screen and (max-width: 1200px) {
    .About_image_container_left_text h2 {

        font-size: clamp(25px, 16.5vw, 68px);

    }
}




.About_image_container_right_text {
    position: absolute;
    top: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

.About_image_container_right_text h2 {

    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(1vw, 80px, 5.5vw);
    font-style: normal;
    font-weight: 400;
    line-height: 85%;

    text-transform: uppercase;
    margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
    .About_image_container_right_text h2 {

        font-size: clamp(25px, 12.5vw, 58px);

    }
}


.About_image_container_right_text p {
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(0.5vw, 16px, 2.5vw);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding: 0 20%;
}




@media screen and (max-width: 1100px) {
    .About_image_container_right_text p {

        font-size: clamp(9px, 1.6vw, 14px);
        margin-bottom: 10px;
        text-align: center;
        padding: 0 10%;
    }
}


.About_contact_button {

    position: relative;
    border: none;
    padding: 30px 20px;
    margin: 25px 0;
    width: 15vw;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

@media screen and (max-width: 1100px) {
    .About_contact_button {
        width: 65vw;
        font-size: 0.8rem;


    }
}

@media (hover: hover) {


    .About_contact_button:hover {
        cursor: pointer;
    }


}