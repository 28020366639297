.PrivacyPolicy {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 40px;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

.privacy-container h1,
.privacy-container h2 {
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.privacy-container p,
.privacy-container ul {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.6;
}

.privacy-container ul {
    margin-left: 40px;
}

.privacy-container a {
    color: #10c9d2;
    text-decoration: underline !important;
    transition: color 0.3s;
}




.privacy-container footer {
    font-size: 0.85rem;
    color: #777;
    border-top: 1px solid #f1f1f1;
    padding-top: 20px;
    margin-top: 40px;
}