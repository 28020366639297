.CookiesDetails {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-container {
    font-family: 'Poppins', sans-serif;
    background-color: #F4F6F6;
    /* světlá šedá barva */
    padding: 60px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* mírný stín */
    color: #333;
    /* tmavá šedá barva */
    max-width: 800px;
    margin: 20px auto;
}

.details-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.details-container a {
    color: #10c9d2;
    text-decoration: underline !important;
}

.details-content {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
}

.details-section {
    margin-bottom: 20px;
}

.details-section-title {
    font-weight: bold;
    margin-bottom: 10px;
}