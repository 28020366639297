/* Kontejner pro fotografie */
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding: 15vh 10vw 5vh 10vw;
    justify-content: center;
    font-family: 'Poppins', sans-serif;

}



@media screen and (max-width: 900px) {
    .gallery {
        display: grid;
        justify-content: center;

        grid-gap: 6px;
        margin-bottom: 10vh;

        padding: 25vh 0vw 0 0vw;
    }
}

.sykora_bg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(20deg);
    pointer-events: none;
    opacity: 0.3;
}

.gallery-selector {
    z-index: 99;
    position: absolute;
    right: 50px;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    overflow: hidden;
    height: 50px;
    width: 150px;
}

@media screen and (max-width: 769px) {
    .gallery-selector {
        top: auto;
        bottom: 30px;
        height: 50px;
        width: 100vw;
        right: 0;
        border-radius: 0;
        position: relative;

    }
}

.gallery-selector select {
    background-color: #ffffff;
    color: #000000;
    border: none;
    text-align: center;

    border-radius: 25px;
    font-weight: 600;
    letter-spacing: 1px;

    cursor: pointer;
    transition: background-color 0.2s;

    height: 50px;
    width: 150px;
}

@media screen and (max-width: 769px) {
    .gallery-selector select {

        width: 100%;
    }
}




/* Styly pro jednotlivé fotografie */
.photo-item {
    z-index: 10;
    max-width: calc(33.33% - 1em);
    min-width: fit-content;
    height: auto;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    background-color: #fff;
    padding: 20px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
}

@media screen and (max-width: 900px) {
    .photo-item {
        max-width: calc(100%);
        margin: 0;

    }
}



.photo-item img {
    height: 100%;
    min-height: 150px;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;

}



.photo-item p {
    font-size: 14px;
    font-weight: 600;
    color: #555;
    text-align: center;
    max-width: 300px;
}

/* Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.modal-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 769px) {
    .modal-overlay {
        background-color: rgba(0, 0, 0, 0.9);
    }


}

.modal-content img {
    width: auto;
    height: auto;
    max-width: 1000px;
    max-height: 80vh;

}

@media screen and (max-width: 769px) {
    .modal-content img {
        width: 100%;
        height: 50vh;
        /* Adjust based on your needs */
        object-fit: cover;
        overflow: scroll;
        /* This prevents stretching */
    }
}

/* Skeleton při načítání obrázků */
@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.skeleton {
    width: 320px;
    height: 270px;
    margin: 10px;
    background: linear-gradient(90deg, #f0f0f0, #f8f8f8, #f0f0f0);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border-radius: 10px;
    z-index: 5;
}

.skeleton_foto {
    background: linear-gradient(90deg, #d3d2d2, #c4c3c3, #d3d2d2);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    animation: shimmer 2.5s infinite;

}

.skeleton_text {
    background: linear-gradient(90deg, #d3d2d2, #c4c3c3, #d3d2d2);
    width: 90%;
    height: 10%;
    border-radius: 5px;
    animation: shimmer 1.5s infinite;

}


.gallery-nxtBtn {
    z-index: 99;
    position: fixed;
    right: 50px;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    overflow: hidden;
    height: 50px;
    width: 150px;
}

/* Styling pro předchozí a další tlačítka */
.modal-content-btn {
    position: absolute;
    top: 0;
    width: 5vw;
    height: 100vh;
    min-width: fit-content;
    padding: 30px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #00000000;
    background-color: #00000000;
    border: none;

}

@media screen and (max-width: 1200px) {
    .modal-content-btn {
        top: 85vh;
        width: 5%;

    }
}


.modal-content-btn:nth-child(2) {
    left: 0;
}

.modal-content-btn:nth-child(3) {
    right: 0;
}



/* Umístění tlačítek v modálním okně */
.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: rgba(0, 0, 0, 0.5);
    /* Poloprůhledné černé pozadí */
    color: white;
    /* Bílá barva textu */
    font-size: 24px;
    padding: 2px 7px;
    /* Malý okraj kolem textu */
    border-radius: 5px;
    /* Zaoblené rohy */
    cursor: pointer;
}









@media (hover: hover) {

    .modal-content-btn button:hover {
        background-color: #0a7075cc;
        color: #ffffff;
        border: none;
    }


    /* when hover is supported */
    .photo-item:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .photo-item img:hover {
        transform: scale(1.05);
        cursor: pointer;
    }


    .gallery-nxtBtn button:hover {
        background-color: #0a6f75;
        color: white;
    }

    /* Efekt pro tlačítka modal */

    .modal-content-btn:hover {
        background-color: #0a7075cc;
        color: #ffffff;
        border: none;
        cursor: pointer;
    }
}