.Birds {
    position: absolute;
    width: 100vw;
    height: 100vh;
    animation-name: fly-position;
    animation-duration: 5.5s;
    overflow: hidden;

}

.bird {

    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg');

    filter: invert(34%) sepia(55%) saturate(427%) hue-rotate(141deg) brightness(93%) contrast(91%);

    background-size: auto 100%;
    width: 88px;
    height: 125px;
    will-change: background-position;


    animation-name: fly-cycle;
    animation-timing-function: steps(10);
    animation-iteration-count: infinite;
}

.bird-one {
    animation-duration: 1s;
    animation-delay: -0.5s;


}

.bird-two {
    animation-duration: 0.9;
    animation-delay: -0.75.s;

}

.bird-three {
    animation-duration: 1.25s;
    animation-delay: -0.25s
}

.bird-four {
    animation-duration: 1.1s;
    animation-delay: -0.5s;
}

.bird-five {
    animation-duration: 1.2s;
    animation-delay: -0.5s;
}

.bird-six {
    animation-duration: 1.4s;
    animation-delay: -0.5s;
}

.bird-container {
    position: absolute;
    top: 10%;
    left: -3%;
    transform: scale(0) translateX(-10vw);
    will-change: transform;

    animation-name: fly-right-one;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.bird-container-one {
    animation-duration: 15s;
    animation-delay: 0;
}

.bird-container-two {
    animation-duration: 16s;
    animation-delay: 1s;
}

.bird-container-three {
    animation-duration: 14.6s;
    animation-delay: 9.5s;
}

.bird-container-four {
    animation-duration: 16s;
    animation-delay: 10.25s;
}

.bird-container-five {
    animation-duration: 13s;
    animation-delay: 10.25s;
}

.bird-container-six {
    animation-duration: 14s;
    animation-delay: 11.25s;
}

@keyframes fly-cycle {
    100% {
        background-position: -3600px 0;
    }
}

@keyframes fly-cycle {

    100% {
        background-position: -900px 0;
    }

}

@keyframes fly-position {

    0% {
        z-index: 1;
    }

    50% {
        z-index: 43;
    }

    100% {
        z-index: 1;
    }

}

@keyframes fly-right-one {

    0% {
        transform: scale(0.3) translateX(-10vw);
    }

    10% {
        transform: translateY(2vh) translateX(10vw) scale(0.4);
    }

    20% {
        transform: translateY(0vh) translateX(30vw) scale(0.5);
    }

    30% {
        transform: translateY(4vh) translateX(50vw) scale(0.6);
    }

    40% {
        transform: translateY(2vh) translateX(70vw) scale(0.6);
    }

    50% {
        transform: translateY(0vh) translateX(90vw) scale(0.6);
    }

    60% {
        transform: translateY(0vh) translateX(110vw) scale(0.6);
    }

    100% {
        transform: translateY(0vh) translateX(110vw) scale(0.6);
    }

}

@keyframes fly-right-two {

    0% {
        transform: translateY(-2vh) translateX(-10vw) scale(0.5);
    }

    10% {
        transform: translateY(0vh) translateX(10vw) scale(0.4);
    }

    20% {
        transform: translateY(-4vh) translateX(30vw) scale(0.6);
    }

    30% {
        transform: translateY(1vh) translateX(50vw) scale(0.45);
    }

    40% {
        transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
    }

    50% {
        transform: translateY(0vh) translateX(90vw) scale(0.45);
    }

    51% {
        transform: translateY(0vh) translateX(110vw) scale(0.45);
    }

    100% {
        transform: translateY(0vh) translateX(110vw) scale(0.45);
    }

}