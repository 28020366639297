/* General styles for both components */
.upload-section,
.password-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 500px;
    margin: 50px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-section h2,
.password-section h2 {
    margin-bottom: 20px;
}

/* UploadPhoto Styling */
.upload-section input[type="file"],
.upload-section textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.upload-section button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upload-section button:hover {
    background-color: #0056b3;
}

.upload-section select {
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
}

/* PasswordProtection Styling */
.password-section input[type="email"],

.password-section input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.password-section p {
    margin-bottom: 10px;
}

.password-section button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.password-section button:hover {
    background-color: #0056b3;
}




/* Styling pro seznam fotografií */
.photos-list {
    padding: 0;
    list-style-type: none;
    max-width: 600px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.photos-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}

.photos-list li:last-child {
    border-bottom: none;
}

.photos-list button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.photos-list button:hover {
    background-color: #0056b3;
}