@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --dark: #242423;
    --light: #e6e6e6;
}


/*  ========================================================================== 
    Layout
    ========================================================================== */

.landing {
    position: relative;
    width: 100vw;
    min-height: 100vh;

    color: var(--dark);
    line-height: 1.3;
    top: 0;
    overflow: hidden;
}

.landing_wrap {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 80vw;


    display: flex;
    justify-content: space-between;

    max-width: 2400px;
    margin: 0 auto;

}

@media screen and (max-width: 769px) {
    .landing_wrap {
        flex-direction: column-reverse;
        align-items: center;
        top: 55%;

    }
}

.landing_wrap_col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

@media screen and (max-width: 769px) {
    .landing_wrap_col1 {
        margin-right: 32%;
        max-height: 50vh;
    }
}



.landing_wrap_col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 769px) {
    .landing_wrap_col2 {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
    }
}



/*  ========================================================================== 
    Images
    ========================================================================== */

.landing_wrap_col1 img {
    max-width: 600px;
    width: 25vw;
    display: flex;
    flex-direction: column;


}

@media screen and (max-width: 769px) {
    .landing_wrap_col1 img {
        max-width: 600px;
        width: 95vw;
        display: flex;
        flex-direction: row;


    }
}

.landing_wrap_col1 img:nth-child(2) {
    z-index: -5;
    margin-top: 50px;
}


@media screen and (max-width: 769px) {
    .landing_wrap_col1 img:nth-child(2) {
        margin-top: 0px;
        margin-left: -50px;
    }
}

/*  ========================================================================== 
    Texts
    ========================================================================== */

.landing__hero {

    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}

.landing__hero h1 {
    font-size: clamp(14px, 15vw, 500px);
    letter-spacing: -1vw;
    line-height: 1;
    font-family: "Cinzel", serif;
    font-weight: 400;
    visibility: inherit;
    color: var(--light);


}

@media screen and (max-width: 769px) {
    .landing__hero h1 {
        font-size: clamp(18px, 26vw, 100px);

    }
}

.landing__text {
    padding: 0 20%;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(12px, 0.6vw + 0.4rem, 20px);
    z-index: 5;
    display: flex;
    justify-content: center;
    text-align: justify;
    font-weight: 400;
}


@media screen and (max-width: 769px) {
    .landing__text {
        padding: 0 10%;
        font-size: clamp(12px, 0.4vw + 0.4rem, 20px);
        text-align: center;
    }
}

/*  ========================================================================== 
    Block and scrollsign 
    ========================================================================== */


.center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20%;
    display: flex;
    justify-content: center;
    animation: animateVisibility 5s ease-in;
    opacity: 0;
}



#circle--shape {
    animation: animateShape 1.6s ease-in-out 4;

}


@media only screen and (min-width: 1440px) {}

@media only screen and (min-width: 2000px) {}

/*  ========================================================================== 
    KeyFrames
    ========================================================================== */

@keyframes animateVisibility {
    0% {

        opacity: 1;
    }

    100% {

        opacity: 0;
    }
}

@keyframes animateShape {
    50% {
        cy: 100;

    }
}