@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap');

:root {
    --dark: #242423;
}

nav {
    position: fixed;
    left: 0;
    top: 0;
    font-family: 'Cinzel', serif;
    z-index: 1000;
}

.navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
}

.navbar-logo {
    margin-left: 50px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 50px;
}

@media screen and (max-width: 769px) {
    .navbar-logo {
        width: 100vw;
        height: 100px;
        margin-top: 0;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.navbar-logo h2 {
    line-height: 1;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -1px;
    color: var(--dark);
    text-decoration: none;
}

.navbar-logo a {

    text-decoration: none;
}

.navbar-menu {

    cursor: pointer;
    width: 50px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.line {
    height: 3px;
    background: var(--dark);
    width: 100%;
    margin-top: 5px;

}

.line:nth-child(1),
.line:nth-child(2) {
    height: 2px;
    width: 80%;
}

.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
    height: fit-content;
    background: #fff;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;

    /* aby bylo nad ostatními prvky */
}

.nav-item {
    opacity: 0;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
}

.nav-item:hover {
    font-weight: 500;
}


.NavbarBottom {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 30px;
    background-color: #1F273B;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbarBottomWrap {
    background-color: #1F273B;
    width: 30vw;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #000000;
}

.navbarBottomWrap a {
    color: #c0d7d8 !important;

}

.navbarBottomWrap a:hover {
    color: #212525 !important;

}

.NavbarBottomIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    background-color: #1F273B;
    cursor: pointer;
    transition: 0.5s;
}






@media (hover: hover) {

    /* when hover is supported */
    .NavbarBottomIcon:hover {
        background-color: rgb(255, 255, 255);
        color: #000000;

        border-radius: 20px;

    }
}