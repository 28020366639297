:root {
    --dark: #242423;
    --light: #e6e6e6;
}

.slide {
    max-width: 2800px;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;
}

@media screen and (max-width: 1100px) {
    .slide {
        flex-direction: column-reverse;
    }
}

.style-5 .slide {
    flex-direction: column;
}





.style-1 .col__content,
.style-2 .col__content,
.style-3 .col__content,
.style-4 .col__content,
.style-5 .col__content {
    background: #DBE6E7;

}



/*  Columns
========================================================================== */

.col {
    flex-basis: 50%;


}

.col--1 {
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    background-color: #c0d7d8;

}

.col__content-wrap {
    width: 100%;
}

.style-1 .col--2,
.style-2 .col--2,
.style-3 .col--2,
.style-4 .col--2,
.style-5 .col--2 {
    position: relative;
    overflow: hidden;
}


.col__content {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    /* padding: 6vw 6vw 10vw; */
}




.col__content-title {
    font-size: clamp(1vw, 180px, 5vw);
    letter-spacing: -0.6vw;
    font-family: "Cinzel", serif;
    font-weight: 400;
    display: inline-block;
    align-self: flex-start;

    position: relative;
    top: 0;
    left: 0;

    will-change: transform;
}

@media screen and (max-width: 769px) {
    .col__content-title {
        font-size: clamp(20px, 13vw, 48px);
    }
}


.line__inner_no {
    color: #0a6f75;
    font-size: 12px;
    letter-spacing: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;

}

.col__content-txt {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(18px, 40px, 64vw);
    color: #353533;
    padding: 0 10%;

    width: 100%;

}


@media screen and (max-width: 769px) {
    .col__content-txt {
        font-size: clamp(12px, 6vw, 22px);
    }
}




.slide-link {
    position: relative;

    padding: 50px 0;

    background: #cfdadb;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    gap: 20px;
    text-decoration: underline !important;


}

.slide-link:hover {
    background: #1010102d;

}

.slide-link__text p {
    font-weight: 800;
}



.slide__scroll-line {
    position: absolute;
    left: 26px;
    bottom: 0;
    width: 1px;
    height: 100%;


}



.slide-link__circ {
    position: relative;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    border: 1px solid var(--dark);

}

.slide-link__text {

    font-size: 20px;
    visibility: inherit;
    line-height: 1;
    font-family: "Cinzel", serif;
    font-weight: 400;

    width: 180px;


}



.slide-link__line {
    position: absolute;
    top: 25px;
    left: 0;
    width: 64px;
    height: 3px;
    background: var(--dark);
}

.line {
    overflow: hidden;
}



.slide__scroll-link {
    position: relative;
    right: -113px;
    bottom: 3.5vw;
    display: block;
    width: 140px;
    height: 140px;
    background: var(--dark);
    overflow: hidden;


}

.slide__scroll-line {
    position: absolute;
    left: 20px;
    bottom: 0;
    width: 1px;
    height: 100%;
}


/*  ========================================================================== 
    Column Image
    ========================================================================== */

.col__image-wrap {
    position: absolute;
    left: 0;
    top: -50%;
    width: 100%;
    height: 160vh;
}

@media screen and (max-width: 769px) {
    .col__image-wrap {
        height: 100vh;
    }
}

.style-5 .col__image-wrap {
    position: absolute;
    left: 0;
    top: -100%;
    width: 100%;
    height: 120vh;
}

@media screen and (max-width: 1100px) {
    .style-5 .col__image-wrap {
        top: -50%;
        width: 300vw;
        height: 100vh;
        left: -100%;
    }
}


.img {

    width: 100%;
    height: 80%;

}

.style-5 .img {

    width: 100%;
    height: 80%;

}