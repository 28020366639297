.site-footer {
    position: relative;
    background-color: #1F273B;
    color: #ECF0F1;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', serif;

}

@media screen and (max-width: 769px) {
    .site-footer {
        padding-top: 30px;
        padding-bottom: 50px;
        height: fit-content;
        text-align: center;
    }
}

.footer-links {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

@media screen and (max-width: 769px) {
    .footer-links {
        flex-direction: column;
        gap: 15px;

    }
}

.footer-links a {
    text-decoration: none;
    color: inherit;
    transition: color 0.2s;
}

.footer-links a:hover {
    color: #10c9d2;
}

.footer-copyright {
    font-size: 0.8rem;
}

@media screen and (max-width: 769px) {
    .footer-copyright {
        text-align: center;
    }
}