/* CookiesBanner.css */

.cookie-banner {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #1F273B;
    /* tmavá modrá barva */
    color: #ECF0F1;
    /* světlá šedá barva */
    padding: 15px 20px;
    border-radius: 10px 10px 0 0;
    /* zaoblené rohy nahoře */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    /* mírný stín nahoře */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    gap: 20px;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s, transform 0.5s;
    will-change: opacity, transform;
}

.cookie-banner.active {
    opacity: 1;
    transform: translateY(0);
    text-align: center;
}

.cookie-banner p {
    margin: 0;
    flex: 1;
    font-size: 0.95rem;
}

.cookie-banner a {
    color: #10c9d2;
    text-decoration: underline !important;
}

.cookie-banner label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
}

.cookiesButton {
    display: flex;
    gap: 10px;
}

.cookie-banner button {

    padding: 7px 15px;
    max-width: 500px;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.3s ease;
}

/* Tlačítko Souhlasím */
.cookie-banner button:nth-child(1) {
    background-color: #0a6f75;
    border: 2px solid #0a6f75;
    ;
    /* zelená barva */
    color: #ECF0F1;
}

.cookie-banner button:nth-child(1):hover {
    border: 2px solid white;
}

/* Tlačítko Odmítnout */
.cookie-banner button:nth-child(2) {
    background-color: rgba(255, 0, 0, 0);
    border: 2px solid white;
    color: #ECF0F1;
}

.cookie-banner button:nth-child(2):hover {
    border: 2px solid #0a6f75;
}

.accept-button {

    color: #ECF0F1;
    font-weight: bold;

}

.close-icon {
    align-self: flex-end;
    margin-left: 20px;
    cursor: pointer;
    font-size: 1.2rem;
    background: none;
    border: none;
    color: #ECF0F1;
}

.cookie-options {
    display: flex;
    gap: 10px;
}