@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;



}

:root {
    --dark: #242423;
    --background: #c0d7d8;

}



.App {

    display: flex;
    flex-direction: column;
    background-color: var(--background);
    min-height: 100vh;
    overflow: hidden;



}


/* button {
    background-color: #ffffff;
    color: #555;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
} */





a:-webkit-any-link {
    text-decoration: none;
    color: #636;
}